<template>
	<div class="row">
		<div class="col-12" v-if="telaAjuda[$route.path.substring(1, $route.path.lastIndexOf('/'))]">
         <div class="alert alert-secondary alert-dismissible fade show mb-2" role="alert">
            <strong>{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/") : '' }}</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
            <span>Busque por cupons emitidos por seus caixas.</span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="$store.dispatch('toggleTelaAjuda', $route.path.substring(1, $route.path.lastIndexOf('/')))"></button>
         </div>
      </div>

      <div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
            <i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-0">
               <div class="row">
                  <div class="col-sm-6 col-lg-4 mb-3">
							<label><i class="far fa-store color-theme font-11 me-1"></i> Loja</label>
							<v-select name="Desktop" :options="config.lojas" v-model="pesquisa.loja" label="nomeFantasia" :clearable="false" placeholder="Selecione uma opção" @input="buscarPdvs(pesquisa.loja)">
								<template #option="option">{{ option.nomeFantasia }}<small class="mx-2"><i class="fal fa-grip-lines-vertical"></i></small><i class="far fa-tag font-11 me-1"></i> {{ option.numeroLoja }}</template>
								<template v-slot:no-options>
									<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Solicite permissão ou utilize a tela "Lojas" para adicionar</small>
								</template>
							</v-select>
							<select name="Mobile" class="form-control" v-model="pesquisa.loja" @change="buscarPdvs(pesquisa.loja)">
								<option v-for="(option, index) in config.lojas" :key="index" :value="option">{{ option.nomeFantasia }}&nbsp;&nbsp;|&nbsp;&nbsp;nº {{ option.numeroLoja }}</option>
							</select>
                  </div>
						<div class="col-sm-6 col-lg-4 mb-3">
							<label><i class="far fa-desktop color-theme font-11 me-1"></i> Caixa</label>
							<v-select name="Desktop" :options="config.pdvs" v-model="pesquisa.pdv" :reduce="p => p.id" label="nome" :clearable="false" placeholder="Selecione uma opção">
								<template v-slot:no-options>
									<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Solicite permissão ou utilize a tela "Lojas" para adicionar</small>
								</template>
							</v-select>
							<select name="Mobile" class="form-control" v-model="pesquisa.pdv">
								<option v-for="(option, index) in config.pdvs" :key="index" :value="option.id">{{ option.nome }}</option>
							</select>
						</div>
						<div class="col-9 col-lg-3 mb-3">
							<label><i class="far fa-calendar color-theme font-11 me-1"></i> Data</label>
							<datePicker format="DD/MM/YYYY" :range="true" :clearable="false" :editable="false" v-model="pesquisa.data"></datePicker>
						</div>
						<div class="col-3 col-lg-1 px-0 mb-3 align-self-end">
							<button type="button" class="btn btn-primary w-100" @click="buscarComprovantes(pesquisa, false)"><i class="fas fa-search font-13"></i></button>
						</div>
					</div>
            </div>
         </div>
      </div>

      <div class="col-12" v-if="pesquisa.resultado.length > 0">
			<div class="card mb-0">
				<div class="card-body p-12">
					<div class="row">
						<div class="col mb-2 align-self-center">
							<div class="limitador-1 mb-1"><i class="fas fa-angle-right color-theme me-1"></i> {{ pesquisa.resultado.length }} resultado(s)</div>
						</div>
						<div class="w-max-content mb-2">
							<a href="javascript:;" class="badge badge-default bg-theme mb-1" @click="buscarComprovantes(null, true)"><i class="far fa-share font-10 me-1"></i> Exportar</a>
						</div>
					</div>

					<div class="cards-150 cards-sm-200">
						<comprovante v-for="(cupom, index) in pesquisa.resultado" :key="index" :index="index" :cupom="cupom" :preview="true" @visualizar="verComprovante($event)" />
					</div>

					<div class="w-100 mt-3 mb-2 text-center" v-if="pesquisa.resultado.length % 30 == 0">
						<button type="button" class="btn btn-secondary btn-md font-12" @click="buscarComprovantes(null, false)">Ver mais</button>
					</div>
				</div>
			</div>
      </div>

		<modalComprovante :modalAnterior="null" :tipo="comprovante.tipo" :comprovante="comprovante.dados" />
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import comprovante from '@/components/resultados/Comprovante.vue'
import modalComprovante from '@/components/resultados/ModalComprovante.vue'

export default {
	name: 'Fechamentos',
	data: function () {
		return {
			pesquisa: {'loja': null, 'pdv': null, 'pagina': 0, 'data': [new Date(), new Date()], 'resultado': []},
			comprovante: {'tipo': null, 'dados': null},
			config: {'lojas': [], 'pdvs': []},
			listaPdvs: [],
			listaExportacao: []
		}
	},
	computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
			telaAjuda: state => state.telaAjuda,
         urlRest: state => state.urlRest,
			tabs: state => state.tabs
      })
   },
	watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function (value) {
         Object.assign(this.$data, this.tabs[value].data)
			this.buscarLojas()
      },
      'pesquisa.data' : function (value) {
         for (let i = 0; i < value.length; i++) {
            if (!(value[i] instanceof Date)) {
               value[i] = new Date(value[i])
            }
         }
      }
	},
	components: {
		comprovante, modalComprovante
	},
	methods: {
		verComprovante : function (comprovante) {
			this.comprovante = JSON.parse(JSON.stringify(comprovante))
			$("#modalVerComprovante").modal('show')
		},
		buscarLojas : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)
			this.$store.dispatch('getLojas', null).then((data) => {
				this.config.lojas = data

				if (this.pesquisa.loja == null) {
					this.pesquisa.loja = data[0]
					this.buscarPdvs(data[0])
				}
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      },
		buscarPdvs : function (loja) {
			/* eslint-disable-next-line no-unused-vars */
			return new Promise((resolve, reject) => {
				if (loja != null) {
					this.config.pdvs = loja.pdvs
					this.pesquisa.pdv = loja.pdvs.length > 0 ? loja.pdvs[0].id : null
					
				} else {
					this.pesquisa.pdv = null
					this.config.pdvs = []
				}
	
				resolve()
			})
		},
		buscarComprovantes : function (pesquisa, exportar) {
			if (Math.round((this.pesquisa.data[1].getTime() - this.pesquisa.data[0].getTime()) / (24 * 60 * 60 * 1000)) >= 90) {
				this.$toast.fire({
					icon: 'error',
					title: 'Período da busca maior que 90 dias.'
				});
	
				return
			} else if (this.pesquisa.loja == null || this.pesquisa.pdv == null) {
				this.$toast.fire({
					icon: 'error',
					title: 'Selecione uma loja e/ou caixa.'
				});
	
				return
			}

			this.$store.dispatch('alternarTelaCarregamento', true)
			this.pesquisa.resultado = pesquisa != null ? [] : this.pesquisa.resultado
			Object.assign(this.$data.listaExportacao, this.$options.data.apply(this).listaExportacao)

			this.$axios({
				method: 'post',
				url: this.urlRest +'pdv/searchComprovantes',
				headers: {'Content-Type': 'application/json'},
				data: {
					'pdv': this.pesquisa.pdv, 
					'idLoja': this.pesquisa.loja.id, 
					'dataInicial': this.pesquisa.data[0], 
					'dataFinal': this.pesquisa.data[1], 
					'formaPagamento': 'TOTAL', 
					'pagina': exportar ? -1 : (pesquisa != null ? 0 : this.pesquisa.pagina)
				}
			}).then(response => {
				if (exportar) {
               this.listaExportacao = response.data
            } else {
               this.pesquisa.pagina++;
               this.pesquisa.resultado.push(... response.data)
            }
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)

				if (exportar) {
               this.$store.dispatch('exportarExcel', {
                  'nome': 'Comprovantes',
                  'resultado': this.listaExportacao, 
                  'campos': ['id', 'idPdv', 'formaPagto', 'data', 'valor'], 
                  'lista': null
               })
            }
			});
		}
	},
	mounted() {
		Object.assign(this.$data, this.tabs[this.$route.params.idTab].data)
		this.buscarLojas()
	}
}

</script>